import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GridsState } from '../reducers/grid.reducers';
import { Grid } from '../models/grid.model';
import { State } from '../models/state.model';

export const getGridsState = createFeatureSelector<State>('grids');

export const getGrids = createSelector(getGridsState, (state: State) => state.grids);

export const getGridById = (id: string) => createSelector(getGrids, (state: GridsState) => state.entities[id]);

export const getGridStateById = (id: string) => createSelector(getGridById(id), (state: Grid) => state?.state);

export const getGridPayloadById = (id: string) => createSelector(getGridById(id), (state: Grid) => state?.payload);

export const getGridSelectionsById = (id: string) => createSelector(getGridById(id), (state: Grid) => state?.payload?.selections);

export const getGridSelectionsDataById = (id: string) => createSelector(getGridById(id), (state: Grid) => state?.payload?.selectionsData);
